export default [{
        path: "/",
        name: "dashboard",
        meta: { authRequired: true },
        component: () =>
            import ("./views/dashboards/default"),
    },
    {
        path: "/login",
        name: "login",
        component: () =>
            import ("./views/account/login"),
        meta: { authRequired: false },
    },
    {
        path: "/register",
        name: "Register",
        component: () =>
            import ("./views/account/register"),
        meta: { authRequired: false },
    },
    {
        path: "/forgot-password",
        name: "Forgot password",
        component: () =>
            import ("./views/account/forgot-password"),
        meta: {
            authRequired: false
        },
    },
    {
        path: "/logout",
        name: "logout",
        component: () =>
            import ("./views/account/logout"),
        meta: {
            authRequired: true,
        },
    },

    /************* USERS **************/
    {
        path: "/users",
        name: "users",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/users/index.vue"),
    },
    {
        path: "/users/:id",
        name: "users_show",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/users/show.vue"),
    },


    /************* CONTINENTS **************/
    {
        path: "/managers",
        name: "managers",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/managers/index.vue"),
    },
    {
        path: "/managers/:id",
        name: "managers_show",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/managers/show.vue"),
    },

    /************* CONTINENTS **************/
    {
        path: "/continents",
        name: "continents",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/definitions/continents/index.vue"),
    },

    /************* COUNTRIES **************/
    {
        path: "/countries",
        name: "countries",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/definitions/countries/index.vue"),
    },

    /************* CURRENCIES **************/
    {
        path: "/currencies",
        name: "currencies",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/definitions/currencies/index.vue"),
    },

    /************* CONSTANTES **************/
    {
        path: "/constantes",
        name: "constantes",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/divers/constantes/index.vue"),
    },

    /************* MOYENS **************/
    {
        path: "/moyens",
        name: "moyens",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/moyens/index.vue"),
    },

    /************* MOYENS **************/
    {
        path: "/exchanges",
        name: "exchanges",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/exchanges/index.vue"),
    },

    /************* MOYENS **************/
    {
        path: "/conversions",
        name: "conversions",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/conversions/index.vue"),
    },

    /************* TRANSACTIONS **************/
    {
        path: "/transactions/:id",
        name: "transaction",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/transactions/show.vue"),
    },
    {
        path: "/transactions",
        name: "transactions",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/transactions/index.vue"),
    },




    /********* GENERAL ****** */
    {
        path: "/notifications",
        name: "notifications",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/general/notifications"),
    },

    {
        path: "/profile",
        name: "profile",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/general/profile"),
    },

    {
        path: "/setting",
        name: "setting",
        meta: { authRequired: true },
        component: () =>
            import ("./views/pages/general/setting"),
    },



    // Redirect any unmatched routes to the 404 page. This may
    // require some server configuration to work in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    // {
    //     path: "*",
    //     redirect: "404",
    // },
    {
        path: "/pages/maintenance",
        name: "Maintenance",
        meta: { authRequired: true },
        component: () =>
            import ("./views/utility/maintenance"),
    },
    {
        path: "/pages/coming-soon",
        name: "coming-soon",
        meta: { authRequired: true },
        component: () =>
            import ("./views/utility/coming-soon"),
    },
    {
        path: "/pages/404",
        name: "Error-404",
        meta: { authRequired: true },
        component: () =>
            import ("./views/utility/404"),
    },
    {
        path: "/pages/500",
        name: "Error-500",
        meta: { authRequired: true },
        component: () =>
            import ("./views/utility/500"),
    },
];